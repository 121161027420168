document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.list-accordion > li > div').forEach(div => {
    div.addEventListener('click', event => {
      const li = event.currentTarget.closest('li');
      if (li.classList.contains('plus')) {
        li.classList.remove('plus');
        li.classList.add('minus');
        li.querySelector('ul').style.display = 'block';
      } else {
        li.classList.remove('minus');
        li.classList.add('plus');
        li.querySelector('ul').style.display = 'none';
      }
    });
  });
});
